import React, { useState } from "react";
import { connectHits, SearchBox, Index, InstantSearch, Configure } from "react-instantsearch-dom";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Section, PageWidth as StandardPageWidth, Container, P } from "@util/standard";
import {
  Maybe,
  Query,
  SanityForm,
  SanityNews,
  SanityPage,
  SanitySeo,
  SanityVillage,
} from "@graphql-types";
import { algoliaSearchClient, arraysEquality } from "@util/helper";
import { Header, SEO } from "@shared";
import { usePageMeta } from "@util/logicHooks";
import { colors, mediaQuery, NO_SEARCH_RESULTS_MESSAGE } from "@util/constants";
import { SearchResult } from "@global";
import {
  isSanityVillage,
  isSanityNews,
  isSanityForm,
  isSanityPage,
  isStaticPage,
} from "@util/types";
import { useUrlSearchParam } from "@util/hooks";

interface Props extends PageProps {
  data: Query;
}

interface Connecter {
  onSetResultCount?: (index: number, updatedCount: number) => void;
  index?: number;
}
interface HitsProps extends Connecter {
  hits: Maybe<SanityVillage | SanityNews | SanityPage | SanityForm>[];
}

const ariaLabel = "Search Results";

export default function SearchResults({ data: { sanityHeader } }: Props) {
  usePageMeta(colors.cyan, "search-results");
  const searchInput = useUrlSearchParam();
  // add another number if there is a new index
  const [resultCounts, setResultCounts] = useState([0, 0, 0, 0, 0]);
  const count = resultCounts.reduce((previousValue, currentValue) => {
    return previousValue + currentValue;
  });

  const handleSetResultCount = (index: number, updatedCount: number) => {
    const newCounts = resultCounts.map((count, idx) => {
      if (idx === index) return updatedCount;
      return count;
    });
    if (arraysEquality(resultCounts, newCounts)) return;
    setResultCounts(newCounts);
  };

  return (
    <div>
      <SEO overwriteTitle="Metlifecare - Search Results" slug="search-results" />
      <Header data={sanityHeader} />
      <Section aria-labelledby={ariaLabel}>
        <PageWidth>
          <InstantSearch indexName="villages" searchClient={algoliaSearchClient}>
            <Configure hitsPerPage={1000} />
            <InputWrapper>
              <h1 aria-label={ariaLabel}>Search results</h1>
              <StyledSearchBox defaultRefinement={searchInput} />
            </InputWrapper>
            {Boolean(count) && <P margin="0 0 40px 0">{count} search results</P>}
            {!Boolean(count) && <h4>{NO_SEARCH_RESULTS_MESSAGE}</h4>}

            <Index indexName="villages">
              <CustomSearchHits onSetResultCount={handleSetResultCount} index={3} />
            </Index>
            <Index indexName="guides">
              <CustomSearchHits onSetResultCount={handleSetResultCount} index={0} />
            </Index>

            <Index indexName="staticPages">
              <CustomSearchHits onSetResultCount={handleSetResultCount} index={1} />
            </Index>

            <Index indexName="pages">
              <CustomSearchHits onSetResultCount={handleSetResultCount} index={2} />
            </Index>

            <Index indexName="news">
              <CustomSearchHits onSetResultCount={handleSetResultCount} index={4} />
            </Index>
          </InstantSearch>
        </PageWidth>
      </Section>
    </div>
  );
}

const SearchHits = (props: HitsProps) => {
  const { hits, onSetResultCount, index } = props;

  if (onSetResultCount) onSetResultCount(index!, hits?.length);
  if (hits == null || hits?.length === 0) return null;

  return (
    <Container flexDirection="column">
      {hits.map(hit => {
        if (hit == null) return null;

        if (isSanityVillage(hit)) {
          const { title, villageExcerpt, slug, _id, region, _type } = hit;
          return (
            <SearchResult
              key={_id}
              title={title}
              description={villageExcerpt}
              slug={slug}
              region={region}
              _type={_type}
              linkText={`Go to ${title ?? "village"}`}
            />
          );
        }

        if (isSanityNews(hit)) {
          const { title, excerpt, slug, _id, _type } = hit;
          return (
            <SearchResult
              key={_id}
              title={title}
              description={excerpt}
              slug={slug}
              _type={_type}
              linkText="Go to article"
            />
          );
        }

        if (isSanityForm(hit)) {
          const { title, description, slug, _id } = hit;

          const formatSlug = { current: `guides/${slug?.current}` };

          return (
            <SearchResult
              key={_id}
              title={title}
              description={description}
              slug={formatSlug}
              linkText="Go to guide"
            />
          );
        }

        if (isSanityPage(hit)) {
          const { title, seo, slug, _id } = hit;
          return (
            <SearchResult
              key={_id}
              title={title}
              description={seo?.pageDescription}
              slug={slug}
              linkText="Go to page"
            />
          );
        }

        if (isStaticPage(hit)) {
          const { title, seo, slug, _id } = hit;

          return (
            <SearchResult
              key={_id}
              title={title}
              description={(seo as SanitySeo)?.pageDescription}
              slug={slug}
              linkText="Go to page"
            />
          );
        }

        return null;
      })}
    </Container>
  );
};

const CustomSearchHits: React.ComponentClass<any, Connecter> = connectHits(SearchHits);

export const query = graphql`
  query searchResultsQuery {
    sanityHeader {
      ...sanityHeader
    }
  }
`;

const PageWidth = styled(StandardPageWidth)`
  width: 80%;
  min-height: 60vh;
`;

const InputWrapper = styled(Container)`
  margin: 60px 0;
  width: 100%;
  justify-content: space-between;

  ${mediaQuery.tabletDown} {
    flex-direction: column;
    margin: 60px 0 40px 0;
    h1 {
      margin-bottom: 10px;
    }
  }
`;

const StyledSearchBox = styled(SearchBox)`
  min-width: 200px;
  width: 40%;

  .ais-SearchBox-form {
    position: relative;
    display: flex;
    padding: 16px 25px 16px 55px;
    border: 1px solid ${colors.navy};
    border-radius: 60px;
  }
  .ais-SearchBox-input[type="search"]::-webkit-search-cancel-button {
    display: none;
  }
  .ais-SearchBox-input {
    display: flex;
    border: none;
    &:focus {
      outline: 0;
    }
  }
  .ais-SearchBox-submit {
    position: absolute;
    left: 16px;
    border: none;
    background-color: transparent;
    svg {
      width: 16px;
      height: 16px;
    }
  }
  .ais-SearchBox-reset {
    position: absolute;
    right: 16px;
    cursor: pointer;
    border: none;
    background-color: transparent;
    &:focus {
      outline: 0;
    }
  }
  .ais-SearchBox-resetIcon {
    width: 12px;
    height: 12px;
  }

  ${mediaQuery.tabletDown} {
    width: 100%;
  }
`;
